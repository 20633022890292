.grid-container {
  display: grid;
  font-family: arial;
  grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% auto;
  padding: 1px;
  border: 1px solid rgba(200, 200, 200, 0.8);
}

.grid-container-auto {
  display: grid;
  font-family: arial;
  padding: 1px;
  border: 1px solid rgba(200, 200, 200, 0.8);
}

.grid-container-col-4 grid-container-auto {
  grid-template-columns: auto auto auto auto;
}

.grid-container-auto-col-5 {
  display: grid;
  font-family: arial;
  padding: 1px;
  border: 1px solid rgba(200, 200, 200, 0.8);
  grid-template-columns: auto auto auto auto auto;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 0px solid rgba(200, 200, 200, 0.8);
  padding: 5px;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

.resaltar {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  background-color: #eeeeee;
}

.left {
  text-align: left;
}

.right {
  margin-right: 50%;
  text-align: right;
}

.icompleto {
  grid-column-start: 1;
  grid-column-end: 7;
}

.i1-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.i1-4 {
  grid-column-start: 1;
  grid-column-end: 5;
}

.i1-5 {
  grid-column-start: 1;
  grid-column-end: 6;
}

.item3-6 {
  grid-column-start: 3;
  grid-column-end: 6;
}

.item3-fin {
  grid-column-start: 3;
  grid-column-end: 7;
}

.item5-fin {
  grid-column-start: 5;
  grid-column-end: 7;
}

.enblanco {
  grid-column-start: 1;
  grid-column-end: 7;
  padding: 5px;
  font-size: 20px;
  text-align: center;
}

.line {
  grid-column-start: 1;
  grid-column-end: 7;
  border-top: 1px dashed rgba(200, 200, 200, 0.8);
}

.line-auto-col-4 {
  grid-column-start: 1;
  grid-column-end: 5;
  border-top: 1px dashed rgba(200, 200, 200, 0.8);
}

.line-auto-col-5 {
  grid-column-start: 1;
  grid-column-end: 6;
  border-top: 1px dashed rgba(200, 200, 200, 0.8);
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

#capture {
  margin-left: 0px;
  padding: 10px;
  width: 700px;
  /* height: 800px; */
}
