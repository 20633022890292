.pedido {
  border-radius: 4px;
  padding: 0.15em 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  border: 0px;
  letter-spacing: 0.3px;
  text-align: left;
  width: 100px;
}

.pedido.estado-pendiente {
  background-color: #c8e6c9;
  color: #256029;
}

.pedido.estado-rechazada {
  background-color: #ffcdd2;
  color: #c63737;
}

.pedido.estado-aceptada {
  background-color: #feedaf;
  color: #8a5340;
}

.pedido.estado-enviada {
  background-color: #b3e5fc;
  color: #23547b;
}

.pedido.estado-eliminada {
  background-color: #eccfff;
  color: #694382;
}
