.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 5rem 1rem 2rem 3rem;
  transition: margin-left $transitionDuration;
}

.layout-main {
  flex: 1 1 auto;
}
