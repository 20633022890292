.u-section-1 .u-sheet-1 {
  min-height: 973px;
}

.u-section-1 .u-image-1 {
  width: 570px;
  height: 380px;
  margin: 328px 0 0 auto;
  background-image: url("images/TRIGO.jpg");
  min-height: 312px;
  background-position: 50% 50%;
}

.u-section-1 .u-group-1 {
  width: 696px;
  min-height: 531px;
  margin: -720px auto 0 0;
}

.u-section-1 .u-container-layout-1 {
  padding-left: 120px;
}

.u-section-1 .u-text-1 {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 1.5rem;
  margin: 122px 20px 0 0;
}

.u-section-1 .u-text-2 {
  text-transform: uppercase;
  font-size: 2.25rem;
  margin: 20px 141px 0 0;
}

.u-section-1 .u-line-1 {
  width: 97px;
  transform-origin: left center 0px;
  margin: 30px auto 0 0;
}

.u-section-1 .u-text-3 {
  margin: 30px 192px 0 0;
}

.u-section-1 .u-btn-1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 1px;
  border-radius: 4px;
  margin: 30px auto 0 0;
  padding: 10px 11px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-image-1 {
    margin-top: 354px;
  }

  .u-section-1 .u-group-1 {
    margin-top: -720px;
    margin-bottom: 60px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 16px;
  }

  .u-section-1 .u-text-1 {
    position: relative;
    margin-right: 16px;
  }

  .u-section-1 .u-text-2 {
    position: relative;
    margin-right: 16px;
  }

  .u-section-1 .u-line-1 {
    width: 80px;
    position: relative;
  }

  .u-section-1 .u-text-3 {
    width: auto;
    margin-right: 348px;
  }

  .u-section-1 .u-btn-1 {
    width: 157px;
    height: 41px;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-image-1 {
    margin-top: 504px;
  }

  .u-section-1 .u-group-1 {
    margin-top: -860px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 13px;
  }

  .u-section-1 .u-text-1 {
    margin-right: 13px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 13px;
  }

  .u-section-1 .u-line-1 {
    width: 61px;
  }

  .u-section-1 .u-text-3 {
    position: relative;
    margin-right: 121px;
  }

  .u-section-1 .u-btn-1 {
    position: relative;
    width: 120px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 953px;
  }

  .u-section-1 .u-image-1 {
    width: 540px;
    height: 360px;
  }

  .u-section-1 .u-group-1 {
    position: relative;
    width: 540px;
    margin-top: -775px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 9px;
  }

  .u-section-1 .u-text-1 {
    margin-right: 9px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 9px;
  }

  .u-section-1 .u-line-1 {
    width: 57px;
  }

  .u-section-1 .u-text-3 {
    margin-right: 91px;
  }

  .u-section-1 .u-btn-1 {
    margin: 20px 540px -224px auto;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 839px;
  }

  .u-section-1 .u-image-1 {
    height: 227px;
    margin-top: 612px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-1 .u-group-1 {
    width: 340px;
    min-height: 584px;
    margin-top: -951px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 6px;
  }

  .u-section-1 .u-text-1 {
    margin-right: 6px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 6px;
  }

  .u-section-1 .u-line-1 {
    width: 48px;
  }

  .u-section-1 .u-text-3 {
    margin-right: 57px;
  }

  .u-section-1 .u-btn-1 {
    margin-top: 20px;
    margin-right: 180px;
    margin-bottom: -147px;
    width: 157px;
  }
}
.u-section-2 .u-sheet-1 {
  min-height: 272px;
}

.u-section-2 .u-group-1 {
  width: 817px;
  min-height: 164px;
  height: auto;
  margin: 48px 197px 60px 127px;
}

.u-section-2 .u-container-layout-1 {
  padding-left: 0;
  padding-right: 0;
}

.u-section-2 .u-text-1 {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 22px 20px 0;
}

.u-section-2 .u-text-2 {
  font-weight: 900;
  margin: 20px 20px 0;
}

.u-section-2 .u-line-1 {
  width: 74px;
  position: relative;
  transform-origin: left center 0;
  margin: 30px auto 15px;
}

@media (max-width: 1199px) {
  .u-section-2 .u-group-1 {
    width: 674px;
    position: relative;
    margin-left: 27px;
    margin-right: 97px;
    height: auto;
  }

  .u-section-2 .u-text-1 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-2 .u-text-2 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-2 .u-line-1 {
    width: 61px;
  }
}

@media (max-width: 991px) {
  .u-section-2 .u-group-1 {
    width: 516px;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-1 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-2 .u-line-1 {
    width: 47px;
  }
}

@media (max-width: 767px) {
  .u-section-2 .u-group-1 {
    width: 484px;
  }

  .u-section-2 .u-text-1 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-2 .u-line-1 {
    width: 44px;
  }
}

@media (max-width: 575px) {
  .u-section-2 .u-group-1 {
    width: 340px;
  }

  .u-section-2 .u-text-1 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-2 .u-line-1 {
    width: 37px;
  }
}
.u-section-3 {
  min-height: 312px;
}

.u-section-3 .u-layout-wrap-1 {
  margin: 0 auto 0 0;
}

.u-section-3 .u-image-1 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("images/tractor_safety.jpg");
  min-height: 312px;
  background-position: 50% 50%;
}

.u-section-3 .u-text-1 {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 30px 20px 0;
}

.u-section-3 .u-line-1 {
  width: 79px;
  position: relative;
  transform-origin: left center 0;
  transform: rotate(359.994deg);
  margin: 17px auto 0 20px;
}

.u-section-3 .u-text-2 {
  margin: 17px 20px 20px;
}

.u-section-3 .u-image-2 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("images/stock.jpeg");
  min-height: 312px;
  background-position: 50% 50%;
}

.u-section-3 .u-text-3 {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 30px 20px 0;
}

.u-section-3 .u-line-2 {
  width: 79px;
  position: relative;
  transform-origin: left center 0;
  transform: rotate(359.994deg);
  margin: 17px auto 0 20px;
}

.u-section-3 .u-text-4 {
  margin: 17px 20px 20px;
}

.u-section-3 .u-image-3 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("images/cartera-de-clientes-scaled.jpg");
  min-height: 312px;
  background-position: 50% 50%;
}

.u-section-3 .u-text-5 {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 30px 20px 0;
}

.u-section-3 .u-line-3 {
  width: 79px;
  position: relative;
  transform-origin: left center 0;
  transform: rotate(359.994deg);
  margin: 17px auto 0 20px;
}

.u-section-3 .u-text-6 {
  margin: 17px 20px 20px;
}

@media (max-width: 1199px) {
  .u-section-3 .u-layout-wrap-1 {
    position: relative;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-text-1 {
    position: relative;
    font-size: 1.25rem;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-3 .u-line-1 {
    width: 65px;
    margin-left: 16px;
  }

  .u-section-3 .u-text-2 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-3 .u-text-3 {
    position: relative;
    font-size: 1.25rem;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-3 .u-line-2 {
    width: 65px;
    margin-left: 16px;
  }

  .u-section-3 .u-text-4 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-3 .u-text-5 {
    position: relative;
    font-size: 1.25rem;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-3 .u-line-3 {
    width: 65px;
    margin-left: 16px;
  }

  .u-section-3 .u-text-6 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .u-section-3 .u-image-2 {
    background-position: 50% 10%;
  }
  .u-section-3 .u-text-1 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-3 .u-line-1 {
    width: 50px;
    margin-left: 13px;
  }

  .u-section-3 .u-text-2 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-3 .u-text-3 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-3 .u-line-2 {
    width: 50px;
    margin-left: 13px;
  }

  .u-section-3 .u-text-4 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-3 .u-text-5 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-3 .u-line-3 {
    width: 50px;
    margin-left: 13px;
  }
}

@media (max-width: 767px) {
  .u-section-3 .u-text-1 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-3 .u-line-1 {
    width: 47px;
    margin-left: 9px;
  }

  .u-section-3 .u-text-2 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-3 .u-text-3 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-3 .u-line-2 {
    width: 47px;
    margin-left: 9px;
  }

  .u-section-3 .u-text-4 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-3 .u-text-5 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-3 .u-line-3 {
    width: 47px;
    margin-left: 9px;
  }
}

@media (max-width: 575px) {
  .u-section-3 .u-text-1 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-3 .u-line-1 {
    width: 39px;
    margin-left: 6px;
  }

  .u-section-3 .u-text-2 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-3 .u-text-3 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-3 .u-line-2 {
    width: 39px;
    margin-left: 6px;
  }

  .u-section-3 .u-text-4 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-3 .u-text-5 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-3 .u-line-3 {
    width: 39px;
    margin-left: 6px;
  }
}
.u-section-4 .u-sheet-1 {
  min-height: 500px;
}

.u-section-4 .u-layout-wrap-1 {
  margin: 45px auto 45px 0;
}

.u-section-4 .u-layout-cell-1 {
  min-height: 410px;
}

.u-section-4 .u-container-layout-1 {
  padding-left: 20px;
}

.u-section-4 .u-text-1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 45px 20px 0 0;
}

.u-section-4 .u-text-2 {
  font-weight: 900;
  margin: 15px 20px 0 0;
}

.u-section-4 .u-line-1 {
  width: 77px;
  position: relative;
  transform-origin: left center 0;
  margin: 30px auto 0 0;
}

.u-section-4 .u-text-3 {
  font-style: italic;
  font-size: 1.5rem;
  margin: 30px 20px 40px 0;
}

.u-section-4 .u-layout-cell-2 {
  min-height: 410px;
}

.u-section-4 .u-text-4 {
  margin: 40px 20px 0;
}

.u-section-4 .u-btn-1 {
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;
  margin: 30px 20px 0;
}

@media (max-width: 1199px) {
  .u-section-4 .u-layout-wrap-1 {
    position: relative;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-4 .u-container-layout-1 {
    padding-left: 16px;
  }

  .u-section-4 .u-text-1 {
    position: relative;
    margin-right: 16px;
  }

  .u-section-4 .u-text-2 {
    position: relative;
    margin-right: 16px;
  }

  .u-section-4 .u-line-1 {
    width: 64px;
  }

  .u-section-4 .u-text-3 {
    position: relative;
    margin-right: 16px;
  }

  .u-section-4 .u-text-4 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-4 .u-btn-1 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (max-width: 991px) {
  .u-section-4 .u-container-layout-1 {
    padding-left: 13px;
  }

  .u-section-4 .u-text-1 {
    margin-right: 13px;
  }

  .u-section-4 .u-text-2 {
    margin-right: 13px;
  }

  .u-section-4 .u-line-1 {
    width: 49px;
  }

  .u-section-4 .u-text-3 {
    margin-right: 13px;
  }

  .u-section-4 .u-text-4 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-4 .u-btn-1 {
    margin-left: 13px;
    margin-right: 13px;
  }
}

@media (max-width: 767px) {
  .u-section-4 .u-container-layout-1 {
    padding-left: 9px;
  }

  .u-section-4 .u-text-1 {
    margin-right: 9px;
  }

  .u-section-4 .u-text-2 {
    margin-right: 9px;
  }

  .u-section-4 .u-line-1 {
    width: 46px;
  }

  .u-section-4 .u-text-3 {
    margin-right: 9px;
  }

  .u-section-4 .u-text-4 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-4 .u-btn-1 {
    margin-left: 9px;
    margin-right: 9px;
  }
}

@media (max-width: 575px) {
  .u-section-4 .u-layout-cell-1 {
    min-height: 289px;
  }

  .u-section-4 .u-container-layout-1 {
    padding-left: 6px;
  }

  .u-section-4 .u-text-1 {
    margin-right: 6px;
  }

  .u-section-4 .u-text-2 {
    margin-right: 6px;
  }

  .u-section-4 .u-line-1 {
    width: 38px;
  }

  .u-section-4 .u-text-3 {
    margin-right: 6px;
  }

  .u-section-4 .u-layout-cell-2 {
    min-height: 440px;
  }

  .u-section-4 .u-text-4 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-4 .u-btn-1 {
    margin-right: 15px;
    margin-left: auto;
  }
}
.u-section-5 .u-sheet-1 {
  min-height: 864px;
}

.u-section-5 .u-group-1 {
  width: 765px;
  min-height: 174px;
  margin: 30px auto 0;
}

.u-section-5 .u-container-layout-1 {
  padding-left: 0;
  padding-right: 0;
}

.u-section-5 .u-text-1 {
  margin: 15px 20px 0;
}

.u-section-5 .u-icon-1 {
  color: rgb(244, 203, 8) !important;
}

.u-section-5 .u-text-2 {
  font-weight: 900;
  margin: 20px 20px 0;
}

.u-section-5 .u-line-1 {
  width: 60px;
  position: relative;
  transform-origin: left center 0;
  margin: 30px auto 15px;
}

.u-section-5 .u-layout-wrap-1 {
  margin: 20px auto 0 0;
}

.u-section-5 .u-layout-cell-1 {
  min-height: 603px;
}

/* .u-section-5 .u-image-1 {
  height: 288px;
  margin: 0 auto;
} */
.u-section-5 .u-image-1 {
  height: 288px;
  margin: 0 auto;
  background-image: url("images/66856734-01.jpeg");
}

.u-section-5 .u-text-3 {
  font-weight: normal;
  font-size: 1.875rem;
  margin: 30px 20px 0;
}

.u-section-5 .u-text-4 {
  font-weight: 400;
  font-size: 1rem;
  margin: 10px 20px 0;
}

.u-section-5 .u-text-5 {
  margin: 11px 20px 0;
}

.u-section-5 .u-link-1 {
  text-transform: uppercase;
  padding-bottom: 2px;
  font-weight: 700;
  font-size: 0.875rem;
  margin: 22px auto 0 20px;
}

.u-section-5 .u-layout-cell-2 {
  min-height: 603px;
  background-position: 0% 0%;
}

.u-section-5 .u-image-2 {
  height: 288px;
  margin: 0 auto;
  background-image: url("images/66856734-02.jpeg");
}

.u-section-5 .u-text-6 {
  font-weight: normal;
  font-size: 1.875rem;
  margin: 30px 20px 0;
}

.u-section-5 .u-text-7 {
  font-weight: 400;
  font-size: 1rem;
  margin: 10px 20px 0;
}

.u-section-5 .u-icon-2 {
  color: rgb(0, 0, 0) !important;
}

.u-section-5 .u-text-8 {
  margin: 13px 20px 0;
}

.u-section-5 .u-link-2 {
  text-transform: uppercase;
  padding-bottom: 2px;
  font-weight: 700;
  font-size: 0.875rem;
  margin: 37px auto 0 20px;
}

.u-section-5 .u-layout-cell-3 {
  min-height: 603px;
  background-position: 0% 0%;
}

.u-section-5 .u-image-3 {
  height: 288px;
  margin: 0 auto;
  background-image: url("images/66856734-03.jpeg");
}

.u-section-5 .u-text-9 {
  font-weight: normal;
  font-size: 1.875rem;
  margin: 30px 20px 0;
}

.u-section-5 .u-text-10 {
  font-weight: 400;
  font-size: 1rem;
  margin: 10px 20px 0;
}

.u-section-5 .u-text-11 {
  margin: 11px 20px 0;
}

.u-section-5 .u-link-3 {
  text-transform: uppercase;
  padding-bottom: 2px;
  font-weight: 700;
  font-size: 0.875rem;
  margin: 39px 20px 0;
}

@media (max-width: 1199px) {
  .u-section-5 .u-group-1 {
    width: 631px;
    position: relative;
  }

  .u-section-5 .u-text-1 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-5 .u-text-2 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-5 .u-line-1 {
    width: 49px;
  }

  .u-section-5 .u-layout-wrap-1 {
    position: relative;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 625px;
  }

  .u-section-5 .u-image-1 {
    position: relative;
    height: 237px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-3 {
    position: relative;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-text-4 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-text-5 {
    position: relative;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-link-1 {
    position: relative;
    margin-left: 0;
  }

  .u-section-5 .u-layout-cell-2 {
    min-height: 625px;
  }

  .u-section-5 .u-image-2 {
    position: relative;
    height: 237px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-6 {
    position: relative;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-text-7 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-text-8 {
    position: relative;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-link-2 {
    position: relative;
    margin-left: 0;
  }

  .u-section-5 .u-layout-cell-3 {
    min-height: 625px;
  }

  .u-section-5 .u-image-3 {
    position: relative;
    height: 237px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-9 {
    position: relative;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-text-10 {
    position: relative;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-text-11 {
    position: relative;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-link-3 {
    position: relative;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .u-section-5 .u-group-1 {
    width: 483px;
  }

  .u-section-5 .u-text-1 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-5 .u-text-2 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-5 .u-line-1 {
    width: 38px;
  }

  .u-section-5 .u-image-1 {
    height: 182px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-image-2 {
    height: 182px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-7 {
    margin-top: 35px;
  }

  .u-section-5 .u-text-8 {
    margin-top: 20px;
  }

  .u-section-5 .u-link-2 {
    margin-top: 56px;
    margin-right: 0;
  }

  .u-section-5 .u-image-3 {
    height: 182px;
    margin-right: initial;
    margin-left: initial;
  }
}

@media (max-width: 767px) {
  .u-section-5 .u-group-1 {
    width: 453px;
  }

  .u-section-5 .u-text-1 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-5 .u-text-2 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-5 .u-line-1 {
    width: 36px;
  }

  .u-section-5 .u-image-1 {
    height: 410px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-image-2 {
    height: 410px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-7 {
    margin-top: 20px;
  }

  .u-section-5 .u-text-8 {
    margin-top: 38px;
  }

  .u-section-5 .u-link-2 {
    margin-top: 37px;
  }

  .u-section-5 .u-image-3 {
    height: 410px;
    margin-right: initial;
    margin-left: initial;
  }
}

@media (max-width: 575px) {
  .u-section-5 .u-group-1 {
    width: 340px;
  }

  .u-section-5 .u-text-1 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-5 .u-text-2 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-5 .u-line-1 {
    width: 30px;
  }

  .u-section-5 .u-image-1 {
    height: 258px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-image-2 {
    height: 258px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-7 {
    margin-top: 10px;
  }

  .u-section-5 .u-text-8 {
    margin-top: 13px;
  }

  .u-section-5 .u-image-3 {
    height: 258px;
    margin-right: initial;
    margin-left: initial;
  }
}
.u-section-6 .u-sheet-1 {
  min-height: 268px;
}

.u-section-6 .u-layout-wrap-1 {
  margin: 26px auto 26px 0;
}

.u-section-6 .u-layout-cell-1 {
  min-height: 216px;
}

.u-section-6 .u-text-1 {
  font-size: 3rem;
  margin: 20px 20px 0;
}

.u-section-6 .u-text-2 {
  margin: 20px;
}

.u-section-6 .u-layout-cell-2 {
  min-height: 216px;
}

.u-section-6 .u-container-layout-2 {
  padding-left: 0;
  padding-right: 0;
}

.u-section-6 .u-btn-1 {
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;
  margin: 57px 20px 20px auto;
}

@media (max-width: 1199px) {
  .u-section-6 .u-layout-wrap-1 {
    position: relative;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-6 .u-text-1 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-6 .u-text-2 {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-section-6 .u-btn-1 {
    position: relative;
    margin-right: 16px;
  }
}

@media (max-width: 991px) {
  .u-section-6 .u-text-1 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-6 .u-text-2 {
    margin-left: 13px;
    margin-right: 13px;
  }

  .u-section-6 .u-btn-1 {
    margin-right: 13px;
  }
}

@media (max-width: 767px) {
  .u-section-6 .u-sheet-1 {
    min-height: 348px;
  }

  .u-section-6 .u-text-1 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-6 .u-text-2 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .u-section-6 .u-layout-cell-2 {
    min-height: 80px;
  }

  .u-section-6 .u-btn-1 {
    margin-top: 9px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .u-section-6 .u-sheet-1 {
    min-height: 335px;
  }

  .u-section-6 .u-layout-cell-1 {
    min-height: 155px;
  }

  .u-section-6 .u-text-1 {
    font-size: 2.25rem;
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-6 .u-text-2 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .u-section-6 .u-layout-cell-2 {
    min-height: 154px;
  }

  .u-section-6 .u-container-layout-2 {
    padding-bottom: 0;
  }

  .u-section-6 .u-btn-1 {
    margin-top: 86px;
  }
}
