.titulo {
  font-size: medium;
  /* background-image: linear-gradient(to left, #9cb7d5 , #007a95); */
  color: black;
  border: none;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.1em;
}

.footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  height: "inherit";
}

.crud-dialog {
  top: 0;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  height: 97%;
  max-height: 100% !important;
  width: 60vw;
  transform: scale(1);
  position: absolute;
  background-color: var(--surface-overlay);
}

.p-dialog-header {
  padding: 0.5em !important;
  color: var(--text-color-secondary);
  background-image: linear-gradient(
    90deg,
    #fad961 0%,
    #f76c1cce 100%
  ) !important;
}

.p-dialog .p-dialog-content {
  background: var(--surface-overlay);
  color: var(--text-color-secondary);
  padding: 1rem 1.5rem 2rem 1.5rem;
}
