.p-fluid {
  min-width: 550px;
}

@media (max-width: 640px) {
  .p-fluid {
    min-width: 80vw;
  }
}
.swal2-title {
  font-family: "Lato", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.1rem !important;
}

.swal2-popup {
  font-size: 1.1rem !important;
  font-family: "Lato", "Helvetica Neue", Helvetica, sans-serif;
}
