.datatable-responsive-demo
  .p-datatable-responsive-demo
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
